import { CardBody, Card, Row, Col } from "reactstrap";
import {
  randomFlyoutKey,
  setBaseDocsAddress,
} from "../utility/FlyoutDocsFactory";
import TertiaryButton from "./buttons/TertiaryButton";
import { hideSidebar, showSidebar, toggleFlyout } from "../redux/actions";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import classNames from "classnames";
import "./Flyout.scss";

export const Flyout = () => {
  const sidebar = useAppSelector((state) => state.sidebar);
  const flyout = useAppSelector((state) => state.flyout);
  const dispatch = useAppDispatch();

  const source = flyout.source;
  const section = flyout.section ? `#${flyout.section}` : "";
  const docUrl = `${flyout.docUrl === "" ? setBaseDocsAddress() : flyout.docUrl}?hideborders=true&hidepaddingtop=true&`;
  const flyoutTitle =
    source === "Connection" ? "Documentation" : "Connection Guide";

  // Unique key provided for iframe in order to fix back browser button navigation known issue.
  const unique_key = randomFlyoutKey();

  return (
    <Col
      className={classNames("flyout-column", {
        "flyout-column-visible": flyout.isOpen,
        "flyout-column-hidden": !flyout.isOpen,
      })}
    >
      <div className={"p-0 components-Flyout"}>
        <Card>
          <CardBody className="flyout-height">
            <Row className="title-close-row bottom-divider">
              <Col className="title-container">
                <h3>{flyoutTitle}</h3>
              </Col>
              <Col className="close-btn-container">
                <TertiaryButton
                  onClick={() => {
                    dispatch(
                      toggleFlyout(undefined, flyout.name, flyout.source),
                    );
                    sidebar.keepExpanded
                      ? dispatch(showSidebar())
                      : dispatch(hideSidebar());
                  }}
                  data-testid="button-close-flyout"
                >
                  <span className="x-btn">X</span>Close
                </TertiaryButton>
              </Col>
            </Row>
            <iframe
              key={unique_key}
              scrolling="yes"
              className="iframe-doc"
              frameBorder="0"
              src={`${docUrl}${unique_key}${section}`}
              title="docs"
            ></iframe>
          </CardBody>
        </Card>
      </div>
    </Col>
  );
};
